import * as React from 'react';
import {Link, Route, RouteComponentProps} from 'react-router-dom';
import {Header, Page} from 'components';

type Props = {
    code: number;
    children: React.ReactNode;
};

// Component is used for passing http status for server side rendering;
// For example, if page isn't found, client give page with 404 status code
const Status = ({ code, children }: Props) => {
    const render = ({ staticContext }: RouteComponentProps) => {
        if (staticContext) {
            staticContext.statusCode = code;
        }

        return children;
    };

    return <Route render={render} />;
};

export default function NotFoundPage() {
    return (
        <Status code={404}>
            <Page>
                <div className="wrapper _page-404">
                    <div className="page-404">
                        <Header />
                        <div className="page-404__main">
                            <div className="infinite-line">
                                <div className="infinite-line__block">
                                    <p>Ошибка 404</p>
                                    <p>Ошибка 404</p>
                                    <p>Ошибка 404</p>
                                    <p>Ошибка 404</p>
                                </div>
                            </div>
                            <Link to='/' className="page-404__link">Вернуться на главную</Link>
                        </div>
                    </div>
                </div>
            </Page>
        </Status>
    );
}
